const projects = [
  {
    key: 0,
    name: "Trad-musik",
    description: `
     Une méthode que j’ai beaucoup utilisée pour apprendre 
     l’anglais fut de traduire des paroles de musiques, 
     j’ai créé cet outil pour m’aider dans cette tâche.
     Ce projet n’est actuellement plus en ligne car je travaille
     sur une nouvelle version utilisant React + typescript et firebase.
    `,
    url: "",
    qrcode: "",
    repository: "https://github.com/ditiz/trad-musik",
  },
  {
    key: 1,
    name: "Irregular Verbs",
    description: `
      Site pour aider mon frère à apprendre les verbes irreguliers en anglais.
    `,
    url: "https://irregular-verbs.projets.smarzyk.com/",
    qrcode: "",
    repository: "https://github.com/ditiz/irregular-verbs",
  },
  {
    key: 2,
    name: "Dev log",
    description: `
      Site pour garder une trace des "bouts de codes pratiques"
      me permettant de résoudre des problèmatiques particulières.
      Ce projet m’a sourtout permis de découvrir firebase.
    `,
    url: "https://dev-log.projets.mathieu.smarzyk.com/",
    qrcode: "",
    repository: "https://github.com/ditiz/dev-log",
  },
  {
    key: 3,
    name: "Ancien CV",
    description: `
      Mon ancien projet de CV, remplacer par le site sur lequel vous êtes actuellement.
    `,
    url: "https://curriculum-vitae-a661f.web.app/",
    qrcode: "",
    repository: "https://github.com/ditiz/CV",
  },
  {
    key: 4,
    name: "CV",
    description: `
      Mon nouveux projet de CV, le site sur lequel vous êtes actuellement.
    `,
    url: "https://mathieu.smarzyk.com",
    qrcode: "",
    repository: "https://github.com/ditiz/cvv2",
  },
];

export default projects;
