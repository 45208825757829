import styled from "styled-components";

const Contact = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 8px;
  flex-grow: 1;
`;

export default Contact;
